<template>
  <pop-view @onCloseClick="onCloseClick">
    <div class="edit-user">
      <div class="edit-user-top">
        <p class="edit-user-top-title">编辑用户</p>
        <button class="edit-user-top-close" :style="{
            backgroundImage: `url(${require('@imgs/close-card.png')})`,
          }" @click="onCloseClick"></button>
      </div>
      <div class="edit-user-middle">
        <div class="edit-user-middle-number">
          <must-label :isMust="mustNumber" text="病案号："></must-label>
          <border-input class="edit-user-middle-number-input" @onInput="onNumberInput"
            :initalValue="number"></border-input>
        </div>
        <div class="edit-user-middle-name-container">
          <div class="edit-user-middle-name-container-name">
            <must-label isMust text="姓名："></must-label>
            <border-input class="edit-user-middle-name-container-name-input" @onInput="onNameInput"
              :initalValue="name"></border-input>
          </div>
          <div class="edit-user-middle-name-container-gender">
            <must-label isMust text="性别："></must-label>
            <switch-picker left="男" right="女" :initialIsPickRight="isFemale" @onPick="onGenderPick"></switch-picker>
          </div>
        </div>

        <must-label class="edit-user-middle-phone" isMust :text="`手机号：${phone}`"></must-label>
        <div class="edit-user-middle-first-container">
          <div class="edit-user-middle-first-container-birthday">
            <must-label isMust text="生日："></must-label>
            <!-- <el-date-picker v-model="birthday" type="date" placeholder="选择日期" :append-to-body="false"
              :popper-options="{ positionFixed: true }">
            </el-date-picker> -->
            <el-select filterable v-model="year" placeholder="年" :popper-append-to-body="false"
              @visible-change="yearChange" ref="yearSelect" class="edit-user-middle-first-container-birthday-year">
              <el-option v-for="item in yearList" :key="item.value" :label="item.label" :value="item.value">
              </el-option>
            </el-select>
            <el-select filterable v-model="month" placeholder="月" :popper-append-to-body="false"
              @visible-change="monthChange" ref="monthSelect" class="edit-user-middle-first-container-birthday-month">
              <el-option v-for="item in monthList" :key="item.value" :label="item.label" :value="item.value">
              </el-option>
            </el-select>
            <el-select filterable v-model="day" placeholder="日" :popper-append-to-body="false"
              @visible-change="dayChange" ref="daySelect" class="edit-user-middle-first-container-birthday-day">
              <el-option v-for="item in dayList" :key="item.value" :label="item.label" :value="item.value">
              </el-option>
            </el-select>
          </div>
        </div>
        <div class="edit-user-middle-second-container">
          <div class="edit-user-middle-second-container-height">
            <must-label isMust text="身高："></must-label>
            <border-input class="edit-user-middle-second-container-height-input" @onInput="onHeightInput"
              :initalValue="height">
              <p class="edit-user-middle-second-container-height-unit">cm</p>
            </border-input>
          </div>
          <div class="edit-user-middle-second-container-weight">
            <must-label isMust text="体重："></must-label>
            <border-input class="edit-user-middle-second-container-weight-input" @onInput="onWeightInput"
              :initalValue="weight">
              <p class="edit-user-middle-second-container-height-unit">kg</p>
            </border-input>
          </div>
        </div>
        <div class="edit-user-middle-ID">
          <must-label text="身份证："></must-label>
          <border-input class="edit-user-middle-ID-input" @onInput="onIDInput" :initalValue="ID"></border-input>
        </div>
        <div class="edit-user-middle-address">
          <must-label text="地址："></must-label>
          <border-input class="edit-user-middle-address-input" @onInput="onAddressInput"
            :initalValue="address"></border-input>
        </div>
        <div class="edit-user-middle-row three-word">
          <must-label text="床位号："></must-label>
          <border-input class="edit-user-middle-row-input" @onInput="onBchInput" :initalValue="bch"></border-input>
        </div>
        <div class="edit-user-middle-row two-word">
          <must-label text="科室："></must-label>
          <border-input class="edit-user-middle-row-input" @onInput="onDepartmentInput"
            :initalValue="department"></border-input>
        </div>
      </div>
      <div class="edit-user-bottom">
        <button class="edit-user-bottom-cancel" @click="onCancelClick">
          取消
        </button>
        <button class="edit-user-bottom-confirm" @click="onConfirmClick">
          确定
        </button>
      </div>
    </div>
  </pop-view>
</template>

<script>
import PopView from "@c/common/pop-view.vue";
import BorderInput from "@c/common/border-input.vue";
import SwitchPicker from "@c/device-manage/monitor/device-add/switch-picker.vue";
import MustLabel from "../user-manage/user-add/must-label.vue";
import { DateTool } from "@js/date-tool.js";
export default {
  components: {
    PopView,
    BorderInput,
    SwitchPicker,
    MustLabel,
  },

  props: {
    model: Object,
  },

  data: function () {
    return {
      number: "",
      name: "",
      phone: "",
      isFemale: false,
      birthday: new Date("2002/01/01 00:00:00"),
      height: "",
      weight: "",
      ID: "",
      address: "",
      bch: "",
      department: "",
      yearList: [],
      year: "",
      monthList: [],
      month: "",
      dayList: [],
      day: "",
      userYear: 0,
    };
  },

  computed: {
    mustNumber() {
      //河南省儿童医院要求必填
      return this.$store.state.selectedHospital.hospitalid === 599 || this.$store.state.selectedHospital.hospitalid === 1317
    }
  },

  created: function () {
    this.getUserDetail();
  },

  methods: {
    yearChange(item) {
      if (item) {
        this.$refs.yearSelect.focus();
      } else {
        this.$refs.yearSelect.blur();
      }
    },
    monthChange(item) {
      if (item) {
        this.$refs.monthSelect.focus();
      } else {
        this.$refs.monthSelect.blur();
      }
    },
    dayChange(item) {
      if (item) {
        this.$refs.daySelect.focus();
      } else {
        this.$refs.daySelect.blur();
      }
    },
    calculateDay: function (month) {
      if (
        month == "1月" ||
        month == "3月" ||
        month == "5月" ||
        month == "7月" ||
        month == "8月" ||
        month == "10月" ||
        month == "12月"
      ) {
        this.cycleDay(31);
      } else {
        if (month == "2月") {
          if (this.year.slice(0, 4) % 4 === 0) {
            this.cycleDay(29);
          } else {
            this.cycleDay(28);
          }
        } else {
          this.cycleDay(30);
        }
      }
    },
    cycleDay: function (value) {
      this.dayList = [];
      this.day = "1日";
      for (let index = 1; index <= value; index++) {
        this.dayList.push({ value: `${index}日` });
      }
    },

    calculateYear: function () {
      let date = new Date();
      let year = date.getFullYear();
      this.yearList = [];

      if (this.year == "") {
        if (
          this.month.replace("月", "") > date.getMonth() + 1 ||
          (this.month.replace("月", "") == date.getMonth() + 1 &&
            this.day.replace("日", "") > date.getDate())
        ) {
          this.year = `${this.userYear}  ${year - this.userYear - 1}岁`;
          for (let index = 1900; index <= year; index++) {
            let age = year - index - 1;
            if (age == -1) {
              age = 0;
            }
            this.yearList.push({ value: `${index}  ${age}岁` });
          }
        } else {
          this.year = `${this.userYear}  ${year - this.userYear}岁`;
          for (let index = 1900; index <= year; index++) {
            let age = year - index;
            this.yearList.push({ value: `${index}  ${age}岁` });
          }
        }
      } else {
        if (
          this.month.replace("月", "") > date.getMonth() + 1 ||
          (this.month.replace("月", "") == date.getMonth() + 1 &&
            this.day.replace("日", "") > date.getDate())
        ) {
          this.year = `${this.userYear}  ${year - this.userYear - 1}岁`;
          for (let index = 1900; index <= year; index++) {
            let age = year - index - 1;
            if (age < 0) {
              age = 0;
            }
            this.yearList.push({ value: `${index}  ${age}岁` });
          }
        } else {
          console.log("useryear", this.userYear);
          this.year = `${this.userYear}  ${year - this.userYear}岁`;
          for (let index = 1900; index <= year; index++) {
            let age = year - index;
            this.yearList.push({ value: `${index}  ${age}岁` });
          }
        }
      }
    },

    calculateMonth() {
      for (let index = 1; index <= 12; index++) {
        this.monthList.push({ value: `${index}月` });
      }
    },
    onCloseClick: function () {
      this.$emit("onCloseClick");
    },

    onNumberInput: function (value) {
      this.number = value;
    },

    onNameInput: function (value) {
      this.name = value;
    },

    onPhoneInput: function (value) {
      this.phone = value;
    },

    onGenderPick: function (isPickRight) {
      this.isFemale = isPickRight;
    },

    onHeightInput: function (value) {
      this.height = value;
    },

    onWeightInput: function (value) {
      this.weight = value;
    },

    onIDInput: function (value) {
      this.ID = value;
    },

    onAddressInput: function (value) {
      this.address = value;
    },

    onBchInput: function (value) {
      this.bch = value;
    },

    onDepartmentInput: function (value) {
      this.department = value;
    },

    onCancelClick: function () {
      this.onCloseClick();
    },

    onConfirmClick: function () {
      if (this.mustNumber && this.number === "") {
        this.$toast.showRed(MSG.necessaryEmpty);
        return;
      }
      if (this.name === "" || this.height === "" || this.weight === "") {
        this.$toast.showRed(MSG.necessaryEmpty);
        return;
      }
      this.birthday = new Date(
        (
          `${this.year.slice(0, 4)}-${this.month.replace(
            "月",
            ""
          )}-${this.day.replace("日", "")}` + " 00:00:00"
        ).replace(/-/g, "/")
      );
      if (this.birthday > new Date()) {
        this.$toast.showRed("生日不能晚于今天");
        return;
      }
      if (!isPositiveNumber(this.height)) {
        this.$toast.showRed(MSG.heightNotPosstiveNumber);
        return;
      }
      if (!isPositiveNumber(this.weight)) {
        this.$toast.showRed(MSG.weightNotPosstiveNumber);
        return;
      }
      this.$emit("onConfirmClick", {
        uid: this.model.uid,
        number: this.number,
        name: this.name,
        phone: this.phone,
        isFemale: this.isFemale,
        birthday: DateTool.formatDate(this.birthday, "Y-M-D"),
        height: this.height,
        weight: this.weight,
        ID: this.ID,
        address: this.address,
        hospitalID: this.model.hospitalid,
        bch: this.bch,
        department: this.department,
      });
    },

    async getUserDetail() {
      try {
        const params = {
          id: this.model.id,
        };
        const response = await this.$api.getUserDetail(params);
        this.number = response.mzh;
        this.name = response.trueName;
        this.phone = response.mobile;
        this.isFemale = `${response.sex}` == GENDER_CODE.女;
        if (response.birthday == "") {
          let currentDate = new Date();
          let year = currentDate.getFullYear() - response.age;
          let realBirthday = (year + "-01" + "-01" + " 00:00:00").replace(
            /-/g,
            "/"
          );
          this.birthday = new Date(realBirthday);
        } else {
          this.birthday = new Date(
            (response.birthday + " 00:00:00").replace(/-/g, "/")
          );
        }

        this.userYear = this.birthday.getFullYear();

        this.month = `${this.birthday.getMonth() + 1}月`;

        this.calculateYear();
        this.calculateMonth();
        this.calculateDay(this.month);
        this.day = `${this.birthday.getDate()}日`;
        this.height = response.height;
        this.weight = response.weight;
        this.ID = response.idCard;
        this.address = response.address;
        this.bch = response.bch;
        this.department = response.department;
      } catch (error) {
        this.$toast.showRed(error);
      }
    },
  },

  watch: {
    month(newValue, oldValue) {
      if (oldValue != "") {
        this.calculateYear();
        this.calculateDay(newValue);
      }
    },
    year(newValue, oldValue) {
      this.userYear = newValue.slice(0, 4);
      if (oldValue != "") {
        if (newValue.slice(0, 4) != oldValue.slice(0, 4)) {
          this.month = "1月";
          this.calculateDay(this.month);
        }
      }
    },
    day(newValue) {
      this.calculateYear();
    },
  },
};
</script>

<style lang="scss" scoped>
.edit-user {
  width: rem(483);

  &-top {
    width: 100%;
    height: rem(55);
    border-bottom: rem(1) solid rgba($color: black, $alpha: 0.09);
    box-sizing: border-box;
    padding: rem(0) rem(24);
    display: flex;
    align-items: center;
    justify-content: space-between;

    &-title {
      color: rgba($color: black, $alpha: 0.85);
      font-size: rem(16);
    }

    &-close {
      background-size: rem(16) rem(16);
      background-repeat: no-repeat;
      width: rem(16);
      height: rem(16);
    }
  }

  &-middle {
    color: rgba($color: black, $alpha: 0.85);
    font-size: rem(14);
    line-height: rem(20);
    border-bottom: rem(1) solid rgba($color: black, $alpha: 0.09);
    width: 100%;
    height: rem(560);

    &-number {
      height: rem(32);
      margin-top: rem(30);
      padding-left: rem(71);
      display: flex;
      align-items: center;

      &-input {
        width: rem(286);
        height: 100%;
      }
    }

    &-name-container {
      display: flex;

      &-name {
        height: rem(32);
        margin-top: rem(29);
        margin-left: rem(78);
        display: flex;
        align-items: center;

        &-input {
          width: rem(108);
          height: 100%;
        }
      }

      &-gender {
        margin-left: rem(10);
        margin-top: rem(29);
        height: 100%;
        display: flex;
        align-items: center;

        &-input {
          width: rem(119);
          height: 100%;
        }
      }
    }

    &-phone {
      height: rem(32);
      margin-top: rem(31);
      margin-left: rem(64);
      display: flex;
      align-items: center;

      &-input {
        width: rem(286);
        height: 100%;
      }
    }

    &-first-container {
      display: flex;
      height: rem(32);
      margin-top: rem(32);
      margin-left: rem(78);

      &-birthday {
        height: 100%;
        display: flex;
        align-items: center;

        &-year {
          width: rem(125);
          height: rem(32);
        }

        &-month {
          width: rem(76);
          height: rem(32);
          margin-left: rem(4);
        }

        &-day {
          width: rem(76);
          height: rem(32);
          margin-left: rem(4);
        }

        /deep/ .el-input__inner {
          height: rem(32);
        }

        /deep/.el-input__suffix {
          display: flex;
          align-items: center;
        }

        // /deep/.el-input__inner {
        //   height: 100%;
        //   padding-left: rem(15);
        //   padding-right: rem(15);
        // }

        // /deep/.el-input__icon {
        //   display: none
        // }

        // /deep/.el-select-dropdown el-popper {
        //   z-index: 9999;
        // }
      }
    }

    &-second-container {
      display: flex;
      height: rem(32);
      margin-top: rem(23);
      margin-left: rem(78);

      &-height {
        height: 100%;
        display: flex;
        align-items: center;

        &-input {
          width: rem(108);
          height: 100%;
        }

        &-unit {
          color: #909399;
          font-size: 14px;
          margin-top: -2px;
        }
      }

      &-weight {
        height: 100%;
        display: flex;
        align-items: center;
        margin-left: rem(21);

        &-input {
          width: rem(108);
          height: 100%;
        }
      }
    }

    &-ID {
      height: rem(32);
      margin-top: rem(36);
      margin-left: rem(71);
      display: flex;
      align-items: center;

      &-input {
        width: rem(286);
        height: 100%;
      }
    }

    &-address {
      height: rem(32);
      margin-top: rem(31);
      margin-left: rem(85);
      display: flex;
      align-items: center;

      &-input {
        width: rem(286);
        height: 100%;
      }
    }

    &-row {
      height: rem(32);
      display: flex;
      align-items: center;
      margin-top: rem(31);
      margin-left: rem(85);

      &-input {
        width: rem(286);
        height: 100%;
      }
    }

    .two-word {
      margin-top: rem(31);
      margin-left: rem(85);
    }

    .three-word {
      margin-top: rem(36);
      margin-left: rem(71);
    }
  }

  &-bottom {
    width: 100%;
    height: rem(56);
    box-sizing: border-box;
    padding-top: rem(10);
    padding-right: rem(16);
    display: flex;
    justify-content: flex-end;

    &-confirm {
      color: white;
      background-color: #325fac;
      font-size: rem(14);
      border-radius: rem(3);
      width: rem(65);
      height: rem(32);
      display: flex;
      align-items: center;
      justify-content: center;
    }

    &-cancel {
      color: rgba($color: #000000, $alpha: 0.65);
      background-color: white;
      font-size: rem(14);
      border-radius: rem(3);
      border: rem(1) solid #e8e8e8;
      width: rem(65);
      height: rem(32);
      margin-right: rem(8);
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }

  .number-has-star {
    margin-left: rem(36);
  }

  .number-has-not-star {
    margin-left: rem(43);
  }

  .phone-has-star {
    margin-left: rem(64);
  }

  .phone-has-not-star {
    margin-left: rem(71);
  }
}
</style>
